import React from "react";
import {
  Container,
  Box,
  CircularProgress,
  Divider,
  Typography,
  Stack,
  Paper
} from "@mui/material";
import { LineChart } from "@mui/x-charts/LineChart";
import { PieChart } from "@mui/x-charts/PieChart";

import { getArchieveSummary } from "../services/webArchiveService";

import "./dashboard.css";

const DashboardView = () => {
  const [summary, setSummary] = React.useState(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    if (!summary) {
      getArchieveSummary().then((x) => {
        setSummary(x);
        setLoading(false);
      });
    }
  });

  function getArchiveFileSize(bytes) {
    if (!bytes) return 0;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    const base = 1024;
    const i = Math.floor(Math.log(bytes) / Math.log(base));
    const convertedValue = parseFloat((bytes / Math.pow(base, i)).toFixed(2));
    const convertedUnit = sizes[i];
    return `${convertedValue} ${convertedUnit}`;
  }

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          marginTop: "150px"
        }}
      >
        <CircularProgress />
        <p>Kindly hold on while we get your dashboard ready.</p>
      </div>
    );
  }

  if (!summary) {
    return null;
  }

  return (
    <Container>
      <Typography variant="h3" paddingTop={"10px"}>
        Dashboard
      </Typography>
      <Box component={Paper}>
        {summary && summary.graph && (
          <LineChart
            height={300}
            series={[
              {
                data: Object.values(summary.graph).reverse(),
                label: "Success",
                color: "#4caf50",
                area: true
              },
              {
                data: Object.values(summary.graphFailed).reverse(),
                label: "Failed",
                color: "#f44336",
                showMark: true
              }
            ]}
            xAxis={[
              {
                scaleType: "band",
                label: "Date",
                data: Object.keys(summary.graph)
                  .map((x) => {
                    const dt = new Date(x);
                    return `${dt.getDate()}/${dt.getMonth() + 1}`;
                  })
                  .reverse()
              },
              {
                scaleType: "band",
                label: "Date",
                data: Object.keys(summary.graph)
                  .map((x) => {
                    const dt = new Date(x);

                    return `${dt.getDate()}/${dt.getMonth() + 1}`;
                  })
                  .reverse()
              }
            ]}
          />
        )}
      </Box>

      <Divider />

      <Typography
        variant="h6"
        color={"#2E96FF"}
      >{`Archive scheduler`}</Typography>

      <Box
        component={Paper}
        marginTop={"20px"}
        marginBottom={"20px"}
        display={"flex"}
        alignContent={"center"}
        alignItems={"center"}
        justifyContent={"center"}
        flexDirection={"column"}
        paddingBottom={"10px"}
      >
        <Divider />
        <PieChart
          series={[
            {
              data: [
                {
                  id: 0,
                  value: summary.tags.schedulerCount,
                  color: "rgb(255, 127, 14)",
                  label: `scheduler`
                },
                {
                  id: 1,
                  value: summary.tags.others,
                  color: "rgb(31, 119, 180)",
                  label: `others`
                }
              ],
              innerRadius: 40
            }
          ]}
          width={400}
          height={200}
        />

        <Stack
          component={Paper}
          padding={"10px"}
          style={{ background: "#fafafb", width: "200px", marginRight: "50x" }}
        >
          <Typography
            variant="caption"
            color={"#02B2AF"}
          >{`Scheduler ${summary.tags.schedulerCount}`}</Typography>
          <Typography
            variant="caption"
            color={"#2E96FF"}
          >{`Others ${summary.tags.others}`}</Typography>
        </Stack>
      </Box>

      <Divider />
      <Box
        display={"flex"}
        alignContent={"center"}
        justifyContent={"center"}
        marginTop={"25px"}
      >
        <Box className="today">
          <Typography variant="h5">Today</Typography>

          <Divider />

          <Typography variant="h3">{`${summary.today}`}</Typography>
        </Box>

        <Box className="week">
          <Typography variant="h5">Week</Typography>

          <Divider />

          <Typography variant="h3">{`${summary.thisWeek}`}</Typography>
        </Box>

        <Box className="month">
          <Typography variant="h5">Month</Typography>

          <Divider />

          <Typography variant="h3">{`${summary.thisMonth}`}</Typography>
        </Box>
      </Box>
      <Divider />

      <Box
        display={"flex"}
        alignContent={"center"}
        justifyContent={"center"}
        marginTop={"25px"}
      >
        <Paper>
          <Box className="size">
            <Typography variant="h5">Archive size</Typography>

            <Divider />

            <Typography variant="h3">{`${getArchiveFileSize(
              summary?.totalArchiveSize || 0
            )}`}</Typography>
          </Box>
        </Paper>
        <Paper>
          <Box className="size b1">
            <Typography variant="h5">Archive screenshot size</Typography>

            <Divider />

            <Typography variant="h3">{`${getArchiveFileSize(
              summary?.totalArchiveScreenshotSize || 0
            )}`}</Typography>
          </Box>
        </Paper>
      </Box>
      <Box
        component={Paper}
        marginTop={"25px"}
        padding={"20px"}
        display={"flex"}
        justifyContent={"center"}
      >
        <iframe
          src="https://cf-workers-status-page-production.marcus-878.workers.dev/" // Replace with the URL of the external website
          width="100%"
          height="500px"
          style={{ border: "none" }}
          title="CF Workers status"
        ></iframe>
      </Box>
    </Container>
  );
};

export default DashboardView;
