import React from 'react';
import { Container, Grid, Typography, Link, Divider } from '@mui/material';

const Footer = () => {
    return (
        <div style={{ width: '100%', backgroundColor: '#f5f5f5', padding: '20px 0', marginTop: '20px' }}>
            <Container maxWidth="lg">
                <Grid container spacing={8} paddingLeft={4}>
                    <Grid item xs="auto" md={8}>
                        <Typography variant="h6" color="textPrimary" gutterBottom>
                            <img src="favicon.svg" alt="WebArchive" style={{ height: '160px', width: '160px' }} />
                        </Typography>
                    </Grid>
                    <Grid item xs="auto">
                        <Typography variant="h6" color="textPrimary" gutterBottom>
                            Site
                        </Typography>
                        <Link href="#" underline="hover" color="textSecondary" display="block">Features</Link>
                        <Link href="/status" underline="hover" color="textSecondary" display="block">Status</Link>
                        <Link href="#" underline="hover" color="textSecondary" display="block">Changelog</Link>
                    </Grid>
                    <Grid item xs="auto">
                        <Typography variant="h6" color="textPrimary" gutterBottom>
                            Company
                        </Typography>
                        <Link href="/about" underline="hover" color="textSecondary" display="block">About us</Link>
                        <Link href="/contact" underline="hover" color="textSecondary" display="block">Contact us</Link>
                        <Link href="/docs/gdpr.pdf" download underline="hover" color="textSecondary" display="block">Compliance</Link>
                    </Grid>
                </Grid>
                <Divider style={{border: '2px solid', borderColor: 'black' }} variant="middle"/>
                <Grid container paddingTop={2} columns={{xs: 4, md: 12}} rowSpacing={2} columnSpacing={{ xs: 4, sm: 2, md: 4 }}
                  >
                    <Grid item xs="auto">
                        <Link href="/docs/Terms-of-Use-webarchive.pdf" 
                        download 
                        underline="hover" 
                        color="textPrimary" 
                        display="block">Terms of Use</Link>
                    </Grid>
                    <Grid item xs="auto">
                        <Link href="/docs/Privacy-Policy-webarchive.pdf" 
                        download 
                        underline="hover" 
                        color="textPrimary" 
                        display="block">Privacy Policy</Link>
                    </Grid>
                    <Grid item xs="auto">
                        <Link href="/docs/Data-Protection-Policy-webarchive.pdf" 
                        download 
                        underline="hover" 
                        color="textPrimary" 
                        display="block">Data Protection Policy</Link>
                    </Grid>
                    <Grid item xs="auto" md={3}>
                        <Link href="/docs/Confidentiality-webarchive.pdf"
                        download
                        underline="hover" 
                        color="textPrimary" 
                        display="block">Confidentiality</Link>
                    </Grid>
                    <Grid item xs="auto">
                        <Typography color="textPrimary" gutterBottom>© 2025 Webarchive</Typography>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default Footer;