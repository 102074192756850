import React from "react";
import {
  Container,
  Box,
  Paper
} from "@mui/material";

import "./dashboard.css";

const publicStatusView = () => {
  return (
    <Container>
      <Box
        component={Paper}
        marginTop={"25px"}
        padding={"20px"}
        display={"flex"}
        justifyContent={"center"}
      >
        <iframe
          src="https://cf-workers-status-page-production.marcus-878.workers.dev/" // Replace with the URL of the external website
          width="100%"
          height="500px"
          style={{ border: "none" }}
          title="CF Workers status"
        ></iframe>
      </Box>
    </Container>
  );
};

export default publicStatusView;
