import React, { useState, useEffect } from "react";
import { Container } from "@mui/material";
// import LogoText from "../components/logoText";


const AboutView = () => {
  const [aboutContent, setAboutContent] = useState('');
  useEffect(() => {
    // Import the HTML file
    fetch('/about.html')
      .then(response => response.text())
      .then(content => {
        setAboutContent(content);
      })
      .catch(error => {
        console.error('Error loading about.html:', error);
      });
  }, []);

  return (
    <Container style={{ maxWidth: "800px" }}>
      <div dangerouslySetInnerHTML={{ __html: aboutContent }} />
    </Container>
  );
};

export default AboutView;
